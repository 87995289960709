<template>
	<div id="admin-home">
		<el-menu
			:default-active="activeIndex"
			class="el-menu-demo"
			mode="horizontal"
			@select="handleSelect"
		>
			<el-menu-item index="1">首页</el-menu-item>
			<el-submenu index="2">
				<template slot="title">材料</template>
				<el-menu-item index="2-1">赛事章程下载</el-menu-item>
				<el-menu-item index="2-2">工作计划上传</el-menu-item>
				<el-menu-item index="2-3">体育活动上传</el-menu-item>
			</el-submenu>
			<el-submenu index="3">
				<template slot="title">选项</template>
				<el-menu-item index="3-1">选择计分项目</el-menu-item>
				<el-menu-item index="3-2">查看选项结果</el-menu-item>
			</el-submenu>
			<el-menu-item index="4">参赛资格审查</el-menu-item>
			<el-menu-item index="5">比赛结果公示</el-menu-item>
			<el-submenu index="6">
				<template slot="title">维护</template>
				<el-menu-item index="6-1">院系信息维护</el-menu-item>
				<el-menu-item index="6-2">修改密码</el-menu-item>
			</el-submenu>
			<div id="hello-logout-button">
				<div id="logout-button" @click="logout()">
					<el-link> 退出<i class="el-icon-switch-button"></i> </el-link>
				</div>
				<div id="hello-user" v-if="this.userinfo">
					你好, {{ this.userinfo.username }}
				</div>
			</div>
		</el-menu>
		<div class="line"></div>
		<div
			id="content"
			style="
				margin-top: 15px;
				width: 80%;
				margin-left: auto;
				margin-right: auto;
			"
		>
			<router-view></router-view>
		</div>
	</div>
</template>

<style scoped>
#content {
	margin-top: 15px;
	width: 80%;
	margin-left: auto;
	margin-right: auto;
}
#hello-user {
	float: right;
	height: 60px;
	line-height: 60px;
	margin: 0 16px 0 0;
	border-bottom: 2px solid transparent;
	color: #909399;
}
#logout-button {
	float: right;
	height: 60px;
	line-height: 60px;
	margin: 0 20px 0 0;
	border-bottom: 2px solid transparent;
	color: #909399;
}
#hello-logout-button:focus {
	outline: none;
}
</style>

<script>
import mixin from "../mixins/universalmixins.js";
export default {
	name: "DeptHome",
	data() {
		return {
			activeIndex: "1",
		};
	},
	methods: {
		handleSelect(key) {
			// console.log(key, keyPath);
			switch (key) {
				case "1":
					this.$router.push("/dept");
					break;
				case "2-1":
					this.$router.push("/dept/download-rules");
					break;
				case "2-2":
					this.$router.push("/dept/upload-plan");
					break;
				case "2-3":
					this.$router.push("/dept/upload-sports");
					break;
				case "3-1":
					this.$router.push("/dept/select-game");
					break;
				case "3-2":
					this.$router.push("/dept/all-select");
					break;
				case "4":
					this.$router.push("/dept/query");
					break;
				case "5":
					this.$router.push("/dept/resultlist");
					break;
				case "6-1":
					this.$router.push("/dept/update-info");
					break;
				case "6-2":
					this.$router.push("/dept/change-password");
					break;
				default:
					break;
			}
		},
	},
	mixins: [mixin],
};
</script>
